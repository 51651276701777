import './component.module.css'

import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static classes = ['active']

  toggleSubMenu () {
    this.element.classList.toggle(this.activeClass)
  }
}
