import './component.module.css'

import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['card', 'layer']

  connect () {
    this.setInitialStyles()
    this.cardTarget.addEventListener('mousemove', this.handleMouseMove.bind(this))
    this.cardTarget.addEventListener('mouseout', this.resetStyles.bind(this))
  }

  disconnect () {
    this.cardTarget.removeEventListener('mousemove', this.handleMouseMove)
    this.cardTarget.removeEventListener('mouseout', this.resetStyles)
  }

  setInitialStyles () {
    this.setStyles(0, 0, 0, 0, 0, 0, 0)
  }

  setStyles (X, Y, rX, rY, bgX, bgY, opacity) {
    document.documentElement.style.setProperty('--x', 100 * X + '%')
    document.documentElement.style.setProperty('--y', 100 * Y + '%')
    document.documentElement.style.setProperty('--bg-x', bgX + '%')
    document.documentElement.style.setProperty('--bg-y', bgY + '%')
    document.documentElement.style.setProperty('--r-x', rX + 'deg')
    document.documentElement.style.setProperty('--r-y', rY + 'deg')
    this.layerTarget.style.opacity = opacity
  }

  handleMouseMove (event) {
    const b = this.cardTarget.getBoundingClientRect()
    const X = (b.right - event.clientX) / this.cardTarget.clientWidth
    const Y = (b.bottom - event.clientY) / this.cardTarget.clientHeight

    const rX = -(X - 0.5) * 26
    const rY = (Y - 0.5) * 26

    const bgX = 40 + 20 * X
    const bgY = 40 + 20 * Y

    this.setStyles(X, Y, rX, rY, bgX, bgY, 1)
  }

  resetStyles () {
    this.setStyles(0, 0, 0, 0, 0, 0, 0)
  }
}
