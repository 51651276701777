import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['item']
  static values = {
    defaultIndex: Number
  }

  connect () {
    this.setDefaultItem()
  }

  setDefaultItem () {
    this.itemTargets[this.defaultIndexValue].classList.add(`g-${this.identifier()}-active`)
  }

  expand (event) {
    this.itemTargets.forEach((item, index) => {
      item.classList.remove(`g-${this.identifier()}-active`)

      if (item === event.currentTarget) {
        item.classList.add(`g-${this.identifier()}-active`)
      }
    })
  }
}
