import './component.module.css'

import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static values = {
    quantity: Number,
    confirmMessage: String
  }
  static targets = ['remove', 'modal']

  initialize () {
    if (this.isDialogAvailable()) {
      this.modalTarget.classList.remove('hidden')
    }
  }

  checkDeletion () {
    const form = this.element.querySelector('.js-delete').parentNode
    if (this.quantityValue > 1) {
      form.requestSubmit()
    } else if (this.isDialogAvailable()) {
      this.modalTarget.showModal()
    } else if (window.confirm(this.confirmMessageValue)) {
      this.deleteItem()
    }
  }

  deleteItem () {
    const form = this.element.querySelector('.js-delete').parentNode
    form.requestSubmit()
  }

  isDialogAvailable () {
    return typeof HTMLDialogElement === 'function'
  }
}
