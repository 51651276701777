import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['button', 'form']

  toggle () {
    this.formTargets.forEach((form) => {
      form.classList.toggle('hidden')

      if (!form.classList.contains('hidden')) {
        form.querySelector('input').focus()
      }
    })
  }

  hide (event) {
    if (event && (this.formTargets.some(form => form.contains(event.target)) || this.buttonTarget.contains(event.target))) {
      return
    }

    this.formTargets.forEach((form) => {
      form.classList.add('hidden')
      if (form.nodeName === 'FORM') {
        form.reset()
      } else {
        form.querySelector('form').reset()
      }
    })
  }
}
