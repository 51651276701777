import './component.module.css'

import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  connect () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  closeComponent () {
    this.element.innerHTML = ''
  }
}
