import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['button', 'navigation', 'buttonTemplate']
  static values = {
    buttonDisplayed: Boolean,
    buttonTemplateAdded: {
      type: Boolean,
      default: false
    },
    buttonRemoving: {
      type: Boolean,
      default: false
    }
  }

  disconnect () {
    this.#removeButtonImmediately()
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll () {
    const scrollPosition = window.scrollY
    if (this.buttonDisplayedValue) {
      if (scrollPosition > 64 && !this.buttonTemplateAddedValue && !this.buttonRemovingValue) {
        this.#appendButton()
      } else if (scrollPosition <= 64 && this.buttonTemplateAddedValue && !this.buttonRemovingValue) {
        this.#removeButton()
      }
    }
  }

  #appendButton () {
    const button = document.importNode(this.buttonTemplateTarget.content, true)
    this.navigationTarget.appendChild(button)
    setTimeout(() => this.#animateButtonIn(), 0)
    this.buttonTemplateAddedValue = true
  }

  #removeButton () {
    this.#animateButtonOut()
    this.buttonRemovingValue = true
  }

  #removeButtonImmediately () {
    if (this.hasButtonTarget) {
      this.buttonTarget.remove()
    }
    this.buttonTemplateAddedValue = false
  }

  #animateButtonIn () {
    this.buttonTarget.style.maxWidth = '180px'
  }

  #animateButtonOut () {
    this.buttonTarget.style.maxWidth = '0'
    this.buttonTarget.addEventListener('transitionend', () => {
      this.buttonTarget.remove()
      this.buttonTemplateAddedValue = false
      this.buttonRemovingValue = false
    })
  }
}
