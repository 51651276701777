import './component.module.css'

import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['container', 'prevArrow', 'nextArrow', 'firstLink', 'lastLink']
  static classes = ['hidden']

  connect () {
    this.observationCallback()
  }

  disconnect () {
    this.intersectionObserver.disconnect()
  }

  observationCallback () {
    this.intersectionObserver = new IntersectionObserver(
      this.updateArrowsVisibility.bind(this),
      {
        root: this.containerTarget,
        threshold: 0.6
      }
    )

    this.intersectionObserver.observe(this.firstLinkTarget)
    this.intersectionObserver.observe(this.lastLinkTarget)
  }

  updateArrowsVisibility (entries) {
    for (const entry of entries) {
      const el = entry.target
      const isVisible = entry.intersectionRatio > 0.6
      if (el === this.firstLinkTarget) {
        this.prevArrowTarget.classList.toggle(this.hiddenClass, isVisible)
      }
      if (el === this.lastLinkTarget) {
        this.nextArrowTarget.classList.toggle(this.hiddenClass, isVisible)
      }
    }
  }

  scrollLeft () {
    this.containerTarget.scrollBy(-100, 0)
  }

  scrollRight () {
    this.containerTarget.scrollBy(100, 0)
  }
}
