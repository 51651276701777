import './component.module.css'

import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['container', 'tab']

  connect () {
    const currentTab = window.location.hash.slice(1) || this.defaultTab
    this.setActiveTab({ params: { key: currentTab } })
  }

  get defaultTab () {
    return this.tabTargets[0].querySelector('a').getAttribute(`data-${this.identifier()}-key-param`)
  }

  setActiveTab ({ params: { key } }) {
    this.tabTargets.forEach(tab => {
      const isActive = tab.querySelector('a').getAttribute(`data-${this.identifier()}-key-param`) === key
      tab.classList.toggle(this.classFor('active'), isActive)
    })

    this.dispatchActiveTabEvent(this.containerTarget, key)
  }

  dispatchActiveTabEvent (target, key) {
    const evt = new window.CustomEvent('tab', { detail: key })
    target.dispatchEvent(evt)
  }
}
