import { Application } from '@hotwired/stimulus'

const app = Application.start()

// Import Stimulus controllers
const controllers = import.meta.glob('../../components/**/*.js', { eager: true })

// Manual registration of stimulus controllers
for (const path in controllers) {
  const cleanedPath = path.split('../../components/')[1]
  const mod = controllers[path]

  // Check whether a module has the Controller export defined
  if (!mod.Controller) continue

  // Convert path into a controller identifier:
  //   example/index.js -> example
  //   nav/user_info/index.js -> nav--user-info
  const identifier = cleanedPath.replace(/^\.\//, '')
    .replace(/\/component\.js$/, '')
    .replace(/\//g, '--')

  app.register(identifier, mod.Controller)
}
