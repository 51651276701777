import './component.module.css'

import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['container']

  redirectToLink (event) {
    const selectedOption = event.target.selectedOptions[0]
    const link = selectedOption.getAttribute(`data-${this.identifier()}-link-param`)
    const key = selectedOption.getAttribute(`data-${this.identifier()}-key-param`)

    if (link === '' || key === '') return

    window.location = link
    this.dispatchActiveOptionEvent(key)
  }

  dispatchActiveOptionEvent (key) {
    const target = this.containerTarget
    const evt = new window.CustomEvent('option', { detail: key })
    target.dispatchEvent(evt)
  }
}
