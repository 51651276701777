import { Controller } from '@hotwired/stimulus'

export class BaseController extends Controller {
  identifier () {
    return this.context.module.definition.identifier
  }

  classFor (className) {
    return `g-${this.identifier()}-${className}`
  }
}
