import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static values = { shouldOpen: Boolean }

  connect () {
    if (this.shouldOpenValue) {
      this.element.showModal()
    }
  }
}
