import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['link']

  handleToggle (event) {
    this.linkTargets.forEach(link => {
      if (!link.contains(event.target)) {
        link.open = false
      }
    })
  }
}
