import './component.module.css'

import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['mobileMenuToggle']

  toggleMobileMenu () {
    const target = this.mobileMenuToggleTarget
    const ariaStatus = target.getAttribute('aria-expanded')

    target.classList.toggle('g-organisms--topbar-active')
    target.setAttribute('aria-expanded', !ariaStatus)
    document.body.classList.toggle('overflow-hidden')
    document.body.classList.toggle('mobile-menu-open')
  }
}
