import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['toast']

  static values = {
    removeFromDom: Boolean,
    displayOnRender: {
      type: Boolean,
      default: false
    },
    time: {
      type: Number,
      default: 6000
    }
  }

  connect () {
    if (this.removeFromDomValue) this.hideToast()
    if (this.displayOnRenderValue) this.renderToast()
  }

  renderToast () {
    if (!this.hasToastTarget) {
      const toastTemplate = document.getElementById('toast-template')
      const toastContent = toastTemplate.content.cloneNode(true)
      this.element.appendChild(toastContent)
      this.toastTarget.dataset.state = 'open'

      setTimeout(() => {
        this.hideToast()
      }, this.timeValue)
    }
  }

  hideToast () {
    if (this.hasToastTarget) {
      this.toastTarget.dataset.state = 'closed'
      setTimeout(() => {
        this.toastTarget.remove()
      }, 100)
    }
  }
}
