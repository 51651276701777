import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['button', 'menu']

  toggle () {
    const shouldExpand = this.element.getAttribute('aria-expanded') !== 'true'

    if (shouldExpand) {
      this.renderMenu()
      this.element.setAttribute('aria-expanded', true)
    } else {
      this.hideMenu()
    }
  }

  renderMenu () {
    const menuTemplate = this.element.parentElement.querySelector('#action-menu-template')
    const menuContent = menuTemplate.content.cloneNode(true)
    this.element.appendChild(menuContent)
    this.menuTarget.dataset.state = 'open'
  }

  hideMenu () {
    this.element.setAttribute('aria-expanded', false)
    if (this.hasMenuTarget) {
      this.menuTarget.dataset.state = 'closed'
      setTimeout(() => {
        this.menuTarget.remove()
      }, 100)
    }
  }

  hide (event) {
    if (!this.element.contains(event.target)) {
      this.hideMenu()
    }
  }
}
