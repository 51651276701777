import './component.module.css'
import { BaseController } from '@components/base_controller.js'

export class Controller extends BaseController {
  static targets = ['slides', 'next', 'prev']
  static values = {
    currentIndex: {
      type: Number,
      default: 0
    },
    scrollDistance: Number
  }

  connect () {
    this.scrollDistanceValue = this.slidesTarget.children[0].offsetWidth
    this.showCurrentSlide()
  }

  next () {
    if (this.currentIndexValue < this.slidesTarget.children.length - 1) {
      this.currentIndexValue += 1
      this.showCurrentSlide()
    }
  }

  prev () {
    if (this.currentIndexValue > 0) {
      this.currentIndexValue -= 1
      this.showCurrentSlide()
    }
  }

  showCurrentSlide () {
    const offset = this.currentIndexValue * this.scrollDistanceValue
    this.slidesTarget.style.transform = `translateX(-${offset}px)`
    this.updateControlState()
  }

  updateControlState () {
    this.currentIndexValue <= 0 ? this.prevTarget.setAttribute('disabled', '') : this.prevTarget.removeAttribute('disabled', '')
    this.currentIndexValue >= this.slidesTarget.children.length - 1 ? this.nextTarget.setAttribute('disabled', '') : this.nextTarget.removeAttribute('disabled', '')
  }
}
